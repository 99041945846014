const confirmBox = {
  title: 'Please Confirm',
  size: 'sm',
  buttonSize: 'sm',
  okVariant: 'danger',
  okTitle: 'YES',
  cancelTitle: 'NO',
  footerClass: 'p-2',
  hideHeaderClose: false,
  centered: true,
}

export default confirmBox;
