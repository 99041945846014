<template>
  <div class="">
    <b-card no-body>
      <b-card-title class="font-weight-bolder m-2">Details</b-card-title>
      <section class="mx-2 mb-1 enterprise-details-card__items row">
        <section class="col-4">
          <p class="">Contact Email: <b>{{ enterprise.contact_email }}</b></p>
          <p class="">Password Requirements: <b>{{ enterprise.password_quality }}</b></p>
          <p class="">Two Factor Enabled: <b>{{ enterprise.two_factor_enabled }}</b></p>
          <p class="">Purple Account Enabled: <b>{{ enterprise.purple_account_enabled }}</b></p>
          <p class="">Purple Wallet Enabled: <b>{{ enterprise.purple_wallet_enabled }}</b></p>
          <p class="">Purple Play Video Access: <b>{{ enterprise.purple_play_video_access | capEachWord }}</b></p>
          <p class="">Owner Device Limit: <b>{{ enterprise.owner_device_limit === 0 ? "Unlimited" : enterprise.owner_device_limit }}</b></p>
          <p>Possession Request Message: <b>{{ enterprise.possession_request_message }}</b></p>
        </section>
        <section class="col-sm-4">
          <h4 class="font-weight-bolder mb-2">Data Protection Officer</h4>
          <p class="">Name: <b>{{ enterprise.data_protection_officer_name || '-' }}</b></p>
          <p class="">Email: <b>{{ enterprise.data_protection_officer_email || '-' }}</b></p>
          <p class="mb-0">Phone: <b>{{ enterprise.data_protection_officer_phone || '-' }}</b></p>
        </section>

        <section class="col-sm-4">
          <h4 class="font-weight-bolder mb-2">EU Representative</h4>
          <p class="">Name: <b>{{ enterprise.eu_representative_name || '-' }}</b></p>
          <p class="">Email: <b>{{ enterprise.eu_representative_email || '-' }}</b></p>
          <p class="mb-0">Phone: <b>{{ enterprise.eu_representative_phone || '-' }}</b></p>
        </section>
      </section>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    enterprise: {
      type: Object,
      required: true,
    },
  }
}
</script>
