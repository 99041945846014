<template>
  <div class="">
    <b-card no-body>
      <b-card-title class="font-weight-bolder m-2">Scribe Data Retention</b-card-title>
      <section class="mx-2 mb-1 enterprise-details-card__items row">
        <section class="col-12">
          <p>Audio Files:
            <b> {{ enterprise.scribe_audio_retention_days }} Days</b>
          </p>
          <p>Transcripts:
            <b> {{ enterprise.scribe_transcript_retention_days }} Days</b>
          </p>
          <p>Summaries:
            <b> {{ enterprise.scribe_summary_retention_days }} Days</b>
          </p>
        </section>
      </section>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    enterprise: {
      type: Object,
      required: true,
    },
  }
}
</script>
