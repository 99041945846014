<style lang="scss">
.enterprise-details-card {
  &__items {
    b {
      display: block;
    }
  }
}
</style>
<!--eslint-disable-->
<template>
  <div class="enterpise-apps-page" v-if="!loading">

    <b-card v-if="enterprise">
      <div class="row">
        <div class="col-lg-4 mb-1 mb-lg-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="TabletIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-small-3 mb-0">
                Enterprise Display Name
              </b-card-text>
              <h4 class="font-weight-bolder mb-0">
                {{enterprise.display_name}}
              </h4>
            </b-media-body>
          </b-media>
        </div>

        <div class="col-lg-4 mb-1 mb-lg-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="TabletIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-small-3 mb-0">
                Enterprise UID
              </b-card-text>
              <h4 class="font-weight-bolder mb-0">
                {{enterprise.uid}}
              </h4>
            </b-media-body>
          </b-media>
        </div>

        <div class="col-lg-4 mb-1 mb-lg-0 d-flex align-items-center">
            <b-button @click="switchEnterprise()" class="d-flex ml-auto btn-outline mr-2" variant="success">Switch</b-button>
            <b-btn v-if="$can('Manage', 'All')"
                   :to="{ name: 'enterprise-edit', params: { uid: enterprise.uid }}"
                   variant="primary"
                   class="mr-2">Update
            </b-btn>
        </div>
      </div>
    </b-card>

    <section v-if="!loading">
      <div v-if="enterprise">
        <b-tabs content-class="my-0">

          <b-tab active>
            <template #title><feather-icon icon="InfoIcon" />Details</template>
            <enterprise-details :enterprise="enterprise"/>
          </b-tab>
          <b-tab>
            <template #title><feather-icon icon="GridIcon" />Applications</template>
            <enterprise-applications :uid="enterprise.uid"/>
          </b-tab>
          <b-tab>
            <template #title><feather-icon icon="ServerIcon" />Proxy</template>
            <enterprise-proxy :enterprise-uid="enterprise.uid"/>
          </b-tab>
          <b-tab>
            <template #title><feather-icon icon="ClipboardIcon" />Scribe</template>
            <scribe-data-retention :enterprise="enterprise"/>
          </b-tab>
          <b-tab>
            <template #title><feather-icon icon="AnchorIcon" />Webhooks</template>
            <webhooks :enterprise-uid="enterprise.uid"/>
          </b-tab>
        </b-tabs>
      </div>
      <div v-else>Could not get enterprise</div>
    </section>

    <section v-else>
      <b-spinner variant="primary" label="Loading enterprise"></b-spinner>
    </section>
  </div>
</template>
<!--eslint-enable-->

<script>
import { mapState } from 'vuex'
import auth from '@/auth/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EnterpriseApplications from '@/views/enterprises/viewEnterprises/sections/EnterpriseApplications.vue'
import EnterpriseDetails from "@/views/enterprises/viewEnterprises/sections/EnterpriseDetails.vue";
import EnterpriseProxy from "@/views/enterprises/viewEnterprises/sections/EnterpriseProxy.vue";
import ScribeDataRetention from "@/views/enterprises/viewEnterprises/sections/ScribeDataRetention.vue";
import Webhooks from "@/views/enterprises/viewEnterprises/sections/Webhooks.vue";

export default {
  name: 'EnterpriseApps',
  components: {
    Webhooks,
    ScribeDataRetention,
    EnterpriseProxy,
    EnterpriseDetails,
    EnterpriseApplications,
  },
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapState({
      enterprise: state => state.enterprise.enterprise,
    }),
  },
  mounted() {
    this.getEnterprise()
  },
  methods: {
    switchEnterprise() {
      auth.switch({
        enterprise_uid: this.uid,
      })
        .then(async response => {
          auth.setToken(response.data.accessToken)
          // Response must be authorised so we should be able to use the refresh token to fetch the user details
          await auth.refreshCurrentUser()

          // Make sure they have some permissions
          if (auth.getCurrentUser().permissions == null) {
            this.loginError = 'You do not have the right Role or Permissions. Please contact your Site Administrator'
            return
          }

          this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Switch Successful',
                icon: 'HardDriveIcon',
                variant: 'success',
                text: 'You have now switched enterprises',
              },
            },
            {
              timeout: 2000,
              position: 'top-center',
            })
        }, error => {
          let message
          if (error.error) {
            message = error.error
          } else {
            message = error
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Switch Failed',
              icon: 'HardDriveIcon',
              variant: 'danger',
              text: `The switch failed. Please try again. ${message}`,
            },
          })
        })
    },
    async getEnterprise() {
      this.loading = true
      await this.$store.dispatch('enterprise/getEnterprise', this.uid)
      this.loading = false
    },
  },
}
</script>
