<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <b-modal :id="modalName" title="Private App Management" modal-class="modal-fullscreen" hide-footer>
    <section v-if="!loading">
      <div v-if="tokenDetails" id="private-app-container" class="pa-4" style="height: calc(100VH - 44px);"></div>
      <p v-else>No token found to generate Google Iframe</p>
    </section>

    <b-spinner v-else variant="primary" label="Loading token details"></b-spinner>
  </b-modal>
</template>
<!--eslint-enable-->
<style>
#modal-private-app___BV_modal_body_ {
  padding: 0;
}
</style>
<script>
  import EnterpriseService from '../../../services/EnterpriseService';

  export default {
    components: {},
    props: {
      uid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        modalName: 'modal-private-app',
        loading: true,
        tokenDetails: null,
        iframe: null,
      };
    },
    methods: {
      show() {
        this.getWebToken();
        this.$bvModal.show(this.modalName)
      },
      close() {
        this.$bvModal.hide(this.modalName)
      },
      getWebToken() {
        EnterpriseService.getEnterpriseWebToken(this.uid).then(res => {
          this.tokenDetails = res.data;
          this.setUpGIframe();
        }).catch(err => {
          let error = 'Could not get enterprise web token, please refresh and try again';

          if (err.response && err.response.data) {
            error = `${err.response.data.code}: ${err.response.data.error}`;
          }

          this.$toast.error(error, {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        }).finally(() => {
          this.loading = false;
        });
      },
      setUpGIframe() {
        this.$gapi.getGapiClient().then(gapi => {
          gapi.load('gapi.iframes', () => {
            const token = this.tokenDetails.web_token;
            const options = {
              url: `https://play.google.com/work/embedded/search?token=${token}&mode=SELECT`,
              where: document.getElementById('private-app-container'),
              attributes: { style: 'width: 100%; height:100%', scrolling: 'yes' },
            };

            this.iframe = gapi.iframes.getContext().openChild(options);

            // Create listener. callback might be used later
            // this.iframe.register('onproductselect', event => {
            //   console.log(event);
            // }, gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER);
          });
        })
      },
    },
  };
</script>
