<template>
  <div class="">
    <b-card no-body>
      <b-card-title class="font-weight-bolder m-2">Webhooks</b-card-title>
      <section v-if="!loadingWebhooks" class="m-2 mt-0 enterprise-details-card__items row">
        <!-- Webhook exists -->
        <section v-if="webhook !== null" class="col-12">
          <p>Method: <b>POST</b></p>
          <p>URL: <b>{{ webhook.url }}</b></p>
          <p v-if="webhook.secret">Secret: <b>{{ webhook.secret }}</b></p>

          <div class="d-flex mt-2">
            <b-button
              class="btn btn-danger mr-2"
              @click="showModal('modal-delete-webhook')">
              Delete Webhook
            </b-button>
            <b-button
              class="btn btn-success"
              @click="showModal('modal-update-webhook')">
              Update Webhook
            </b-button>
          </div>
        </section>

        <!-- No webhook exists -->
        <section v-else class="col-4">
          <p>Click below to create a webhook for the enterprise. Once created the secret key for the endpoint will be visible. <b>NOTE: The secret is only available immediately after creation. Make sure to note in down somewhere safe.</b></p>
          <b-button
            class="btn btn-success"
            @click="showModal('modal-create-webhook')">
            Create Webhook
          </b-button>
        </section>
      </section>
    </b-card>

    <!-- Modals -->
    <b-modal id="modal-create-webhook" title="Create Webhook" hide-footer no-close-on-backdrop>
      <CreateWebhookModal
        @create="createWebhook"
        @close="closeModals"
      />
    </b-modal>

    <b-modal id="modal-update-webhook" title="Update Webhook" hide-footer no-close-on-backdrop>
      <UpdateWebhookModal
        v-if="!!webhook"
        :webhook="webhook"
        @update="updateWebhook"
        @close="closeModals"
      />
    </b-modal>

    <b-modal id="modal-delete-webhook" title="Delete Webhook" hide-footer>
      <delete-modal
        v-if="!!webhook"
        title="Are you sure you wish to delete this webhook?"
        subtitle="This will stop all events being sent to this endpoint"
        @close="closeModals"
        @delete="deleteWebhook"
      />
    </b-modal>
  </div>
</template>
<script>
import CreateWebhookModal from "@/views/enterprises/viewEnterprises/sections/CreateWebhookModal.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import UpdateWebhookModal from "@/views/enterprises/viewEnterprises/sections/UpdateWebhookModal.vue";
import WebhookService from "@/services/WebhookService";
import helperService from "@/services/HelperService";

export default {
  components: {UpdateWebhookModal, DeleteModal, CreateWebhookModal},
  props: {
    enterpriseUid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingWebhooks: true,
      webhook: null,
    }
  },
  mounted() {
    this.getWebhook()
  },
  methods: {
    async getWebhook() {
      this.loadingWebhooks = true
      try {
        const res = await WebhookService.get(this.enterpriseUid)
        this.webhook = res.data
      } catch(_) {
      } finally {
        this.loadingWebhooks = false
      }
    },
    async createWebhook({url}) {
      this.closeModals()
      const dto = {
        url: url,
        enterprise_uid: this.enterpriseUid,
      }
      try {
        const res = await WebhookService.create(dto)
        this.webhook = res.data;
        helperService.showNotfySuccess(this.$toast, 'Webhook created!')
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Error creating webhook, please refresh and try again')
      }
    },
    async updateWebhook({url}) {
      this.closeModals()
      const dto = {
        url: url,
      }
      try {
        const res = await WebhookService.update(this.webhook.id, dto)
        this.webhook.url = res.data.url
        helperService.showNotfySuccess(this.$toast, 'Webhook updated!')
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Error updating webhook, please refresh and try again')
      }
    },
    async deleteWebhook() {
      this.closeModals()
      try {
        await WebhookService.delete(this.webhook.id)
        this.webhook = null
        helperService.showNotfySuccess(this.$toast, 'Webhook deleted!')
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Error deleting webhook, please refresh and try again')
      }
    },
    showModal(name) {
      this.$bvModal.show(name)
    },
    closeModals() {
      this.$bvModal.hide('modal-create-webhook')
      this.$bvModal.hide('modal-update-webhook')
      this.$bvModal.hide('modal-delete-webhook')
    },
  }
}
</script>
