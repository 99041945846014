<template>
  <div class="">
    <b-card no-body>
      <b-card-title class="font-weight-bolder m-2">Enterprise Proxy</b-card-title>
      <section v-if="!loadingEnterpriseProxy" class="mx-2 mb-1 enterprise-details-card__items row">
        <section v-if="enterpriseProxy !== null" class="col-12">
          <p>Enabled:
            <b v-if="enterpriseProxy.enabled">Yes</b>
            <b v-else>No</b>
          </p>
          <p>Allowed IP Addresses:
            <b v-if="enterpriseProxy.allowed_ip_addresses === ''">-</b>
            <b v-else>{{ enterpriseProxy.allowed_ip_addresses }}</b>
          </p>
        </section>
      </section>
    </b-card>

    <!-- Proxy exclude lists -->
    <b-card no-body>
      <enterprise-proxy-exclude-lists :uid="enterpriseUid" />
    </b-card>
  </div>
</template>
<script>
import EnterpriseProxyExcludeLists from "@/views/enterprises/viewEnterprises/sections/EnterpriseProxyExcludeLists.vue";
import EnterpriseService from "@/services/EnterpriseService";

export default {
  components: {EnterpriseProxyExcludeLists},
  props: {
    enterpriseUid: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loadingEnterpriseProxy: false,
      enterpriseProxy: null
    }
  },
  mounted() {
    this.getEnterpriseProxy()
  },
  methods: {
    async getEnterpriseProxy() {
      this.loadingEnterpriseProxy = true
      try {
        const res = await EnterpriseService.getEnterpriseProxy(this.enterpriseUid)
        this.enterpriseProxy = res.data
      } catch(_) {
      } finally {
        this.loadingEnterpriseProxy = false
      }
    },
  },
}
</script>
