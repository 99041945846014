<template>
  <div class="create-webhook-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(createWebhook)">
        <b-form-group>
          <!-- URL -->
          <b-input-group class="mb-1 d-flex flex-column mt-1">
            <label>URL</label>
            <validation-provider
              v-slot="validationContext"
              name="url"
              :rules="{ required:true }"
            >
              <b-form-input
                v-model="url"
                placeholder="Enter webhook url..."
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </b-input-group>

          <b-input-group class="d-flex flex-row-reverse mt-3">
            <b-btn variant="primary" type="submit" :disabled="invalid">Create</b-btn>
            <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: 'CreateWebhookModal',
  data() {
    return {
      url: '',
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    createWebhook() {
      this.$emit('create', {url: this.url.trim()});
    }
  },
}
</script>
