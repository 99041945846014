<style lang="scss">
.update-config-modal {
  .table-wrapper {
    max-height: 70vh;
    overflow-y: auto;
  }
}
</style>
<!--eslint-disable-->
<template>
  <b-modal :id="modalName" title="Update Manged Configurations" size="lg" hide-footer>
    <div class="update-config-modal">
      <section class="row mb-2 table-wrapper">
        <b-table
          class="data-table"
          fixed
          hover
          :items="managed_configuration"
          :fields="headers">
          <template #head(actions)="data">
            <b-btn size="sm" variant="primary" @click="addRow(data)">Add</b-btn>
          </template>

          <template #cell(key)="data">
            <b-form-input placeholder="Enter key..." v-model="data.item.key"
                          @input="data.item.key = data.item.key.replace(/\s/g, '')"/>
          </template>
          <template #cell(value)="data">
            <b-form-input placeholder="Enter value..." v-model="data.item.value"/>
          </template>
          <template #cell(actions)="data">
            <b-btn v-if="$can('Manage', 'All')" @click="showDeleteModal(data.index)"
                   variant="danger" size="sm"
                   class="ml-2"><i class="fa fa-times"></i>
            </b-btn>
          </template>
        </b-table>
      </section>

      <section class="d-inline-flex full-width mt-2">
        <div class="ui-spacer"/>
        <b-button class="mr-2" @click="close()">Cancel</b-button>
        <b-button type="submit" variant="primary" @click="updateApp">Update App</b-button>
      </section>

      <b-modal id="modal-remove-config" title="" hide-footer>
        <warning-modal v-if="activeItem > -1" title="Are you sure you wish to remove this pair?"
                       ok-variant="success"
                       @ok="deleteRow(activeItem)"
                       @close="closeModals()"/>
      </b-modal>
    </div>
  </b-modal>
</template>
<!--eslint-enable-->
<script>
import { mapState } from 'vuex'
// eslint-disable-next-line no-unused-vars
import WarningModal from '@/components/modals/WarningModal.vue'
import EnterpriseService from '../../../../services/EnterpriseService'

export default {
  components: {
    WarningModal,
  },
  props: {
    uid: {
      required: true,
      type: String,
    },

  },
  data() {
    return {
      loading: true,
      modalName: 'modal-update-managed-config',
      app: null,
      managed_configuration: [],
      headers: [
        {
          key: 'key',
          label: 'Key',
        },
        {
          key: 'value',
          label: 'Value',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      activeItem: -1,
    }
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    show(app) {
      this.app = app;
      this.managed_configuration = this.convertConfigObjectToArray(this.app.managed_configuration);
      this.$bvModal.show(this.modalName)
    },
    close() {
      this.$bvModal.hide(this.modalName)
    },
    updateApp() {
      const appToUpdate = {
        ...this.app,
      };
      appToUpdate.managed_configuration = this.convertConfigArrayToObject();
      EnterpriseService.updateApplication(this.uid, this.app.id, appToUpdate)
        .then(() => {
          this.$toast.success(`Updated application ${this.app.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.$emit('refresh', true)
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not update application, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    convertConfigArrayToObject() {
      const config = {}

      this.managed_configuration.forEach(item => {
        if (item.key && item.key.length > 0) {
          config[item.key] = item.value || ''
        }
      })

      return config
    },
    convertConfigObjectToArray(configObj) {
      const config = []

      Object.keys(configObj)
        .forEach(item => {
          config.push({
            key: item,
            value: configObj[item],
          })
        })

      return config
    },
    addRow() {
      this.managed_configuration.push({
        key: '',
        value: '',
      })
    },
    deleteRow(index) {
      this.managed_configuration.splice(index, 1)
      this.closeModals()
    },
    showDeleteModal(item) {
      this.activeItem = item
      this.$bvModal.show('modal-remove-config')
    },
    closeModals() {
      this.$bvModal.hide('modal-remove-config')
    },
  },
}
</script>
