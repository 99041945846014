<style lang="scss"></style>

<template>
  <b-modal :id="modalName" title="Update application" hide-footer>
    <div class="update-app-modal">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(addApp)">
          <section class="mb-2">
            <div>
              <label>Name</label>
              <validation-provider
                v-slot="validationContext"
                name="Name"
                :rules="{ required: true, min: 5 }"
              >
                <b-form-input
                  v-model="app.name"
                  :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter name*"
                />

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div>
              <label>Package Name</label>
              <validation-provider
                v-slot="validationContext"
                name="Package"
                :rules="{ required: true, min: 5, appPackageNameValid: true }"
              >
                <b-form-input
                  v-model="app.package_name"
                  :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter package name*"
                />

                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div>
              <label>Track ID</label>
              <b-form-input v-model="app.track" class="mb-1" placeholder="Track ID (optional)" />
            </div>

            <div class="mb-1">
              <validation-provider
                v-slot="validationContext"
                name="Package"
                :rules="{ required: true }"
              >
                <label class="mr-sm-2" for="auto-update-mode">Auto Update Mode</label>
                <b-form-select id="auto-update-mode" v-model="app.auto_update_mode"
                               :state="getValidationState(validationContext)"
                >

                  <b-form-select-option value="AUTO_UPDATE_MODE_UNSPECIFIED">Unspecified</b-form-select-option>
                  <b-form-select-option value="AUTO_UPDATE_DEFAULT">Default</b-form-select-option>
                  <b-form-select-option value="AUTO_UPDATE_POSTPONED">Postponed</b-form-select-option>
                  <b-form-select-option value="AUTO_UPDATE_HIGH_PRIORITY">High Priority</b-form-select-option>
                </b-form-select>
              </validation-provider>
            </div>

            <div class="mb-1">
              <validation-provider
                v-slot="validationContext"
                name="Package"
                :rules="{ required: true }"
              >
                <label class="mr-sm-2" for="default-permissions-policy">Default Permission Policy</label>
                <b-form-select id="auto-update-mode" v-model="app.default_permission_policy"
                               :state="getValidationState(validationContext)"
                >

                  <b-form-select-option value="PROMPT">Prompt</b-form-select-option>
                  <b-form-select-option value="GRANT">Grant</b-form-select-option>
                  <b-form-select-option value="DENY">Deny</b-form-select-option>
                </b-form-select>
              </validation-provider>
            </div>

            <div>
              <b-form-checkbox v-model="app.enable_mdm_auth" name="check-button" switch>
                Enable MDM Authentication
              </b-form-checkbox>
              <b-form-checkbox v-model="app.purple_account_enabled" name="check-button" switch>
                Purple Account Enabled
              </b-form-checkbox>
            </div>
          </section>

          <section class="d-inline-flex full-width mt-2">
            <div class="ui-spacer" />
            <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
            <b-button type="submit" variant="primary">Update Application</b-button>
          </section>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import EnterpriseService from '../../../../services/EnterpriseService'

export default {
  components: {},
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      modalName: 'modal-update-app',
      app: {
        name: '',
        package_name: '',
        track: '',
        enable_mdm_auth: false,
        default_permission_policy: 'PROMPT',
        auto_update_mode: 'AUTO_UPDATE_DEFAULT',
        purple_account_enabled: false,
      },
    }
  },
  methods: {
    show(app) {
      Object.assign(this.app, app)
      this.$bvModal.show(this.modalName)
    },
    close() {
      this.$bvModal.hide(this.modalName)
    },
    addApp() {
      EnterpriseService.updateApplication(this.uid, this.app.id, this.app)
        .then(() => {
          this.$toast.success(`Updated application ${this.app.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })
          this.$emit('refresh', true)
          this.close();
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not update application, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    getValidationState({
      dirty,
      validated,
      valid = null,
    }) {
      return dirty || validated ? valid : null
    },
  },
}
</script>
